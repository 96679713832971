<template>
    <div id="write">
        
        <div class="editor-wrapper">
            <div class="editor-body">
                <el-input type="textarea" v-model="formData.title" placeholder="请输入标题" resize="none"
                    :autosize="true"></el-input>
                <div class="richtext-editor">
                    <Editor ref="tinymce" v-model="formData.content" :id="tinymceId" :init="init" :disabled="disabled" placeholder="请输入正文" />
                </div>
                
            </div>
            <div class="editor-footer">
                <el-button :disabled="!formData.content" @click="handlePublish">发布</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'//编辑器引入
export default {
    name: 'Write',
    components: {
        Editor,
    },
    data() {
        return {
            active: '2-1',
            formData: {
                title: '',
                content: ''
            },
            tinymceId: '1',
            disabled: false,
            init: {
                selector: '#tinymce',
                language_url: '../tinymce/langs/zh_CN.js',//汉化路径是自定义的，一般放在public或static里面
                language: 'zh_CN',
                skin_url: '../tinymce/skins/ui/oxide',
                content_css: `../tinymce/skins/content/default/content.css`,
                plugins: [
                    'link', 'emoticons', 'preview',
                    // 'image',
                ],//插件
                //工具栏
                menubar: false, //隐藏菜单栏
                // image
                toolbar: 'undo redo|bold italic forecolor|link emoticons|preview', //字体大小
                height: 500,//高度
                placeholder: '在这里输入文字',
                branding: false,//隐藏右下角技术支持
                paste_preprocess: function (plugin, args) {
                    console.log(args.content);
                },
                paste_as_text: true,
                //init_instance_callback为回调配置项
                init_instance_callback: (editor) => {
                    // console.log(`回调----`)
                    editor.on('input', e => {
                        // console.log('文本框input触发')
                        this.$emit('input', e.target.innerHTML)
                    });
                    editor.on('change', e => {
                        // console.log('文本框change触发')
                        this.$emit('change', e.level.content)
                    })
                },
                images_upload_url: '',
                // image 图片上传自定义   目前转base64
                images_upload_handler: function (blobInfo, succFun, failFun) {
                    // console.log('images_upload_handler, blobInfo', blobInfo);
                    // console.log('base64', blobInfo.base64());
                    succFun('data:image/png;base64,' + blobInfo.base64());
                }
            }
        }
    },
    activated() {
        console.log('activated, Write');
    },
    mounted () {
        console.log('mounted, Write');
    },
    methods: {
        handlePublish(){
            console.log('handlePublish');
            // /api/article/save
            this.api.postFormAPISM(
                {
                    ...this.formData,
                    is_draft: false,
                    post_article_id: null,
                },
                '/article/save'
            ).then(res => {
                console.log('res', res.data);
                this.$message({
                    type: 'success',
                    message: '发布成功'
                });
                this.formData = {
                    ...this.$options.data().formData,
                }
            }).catch(error => {
                console.log('error', error);
            })
        },
        handleOpen() {

        },
        handleClose() {

        },
        handleHome(){
            let route = this.$router.resolve({
                path: '/'
            });
            console.log('route', route);
            window.open(route.href, '_blank');
        }
    }
}
</script>
<style lang="less">
@import './index.less';
</style>